<template>
    <div class="chat">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">银企对话</div>
       <p class="title">信贷交互流程查看</p>
        <div class="card card1">
            <div class="nums">需求编号：{{approvalData.requireId}}</div>
            <div class="company">{{approvalData.enterName}}</div>
            <div class="details">
                <div class="catalog">
                    <p>流程编号</p>
                    <p>{{approvalData.approvalId}}</p>
                </div>
                <div class="catalog">
                    <p>申请的机构</p>
                    <p>{{approvalData.bankName}}</p>
                </div>
                <div class="catalog">
                    <p>申请人</p>
                    <p>{{approvalData.applicationUserDescr}}</p>
                </div>
            </div>
            <!-- <img class="status" src="@/assets/img/chat_daishouli.png" alt=""> -->
            <img class="status" :src="require(`../../assets/img/sqjd${approvalData.checkStatus}.png`)" alt="">
        </div>
        <p class="title">留言</p>
        <div class="card card2">
            <div class="msgs" v-for="item in messageList" :key="item.messageId">
                <div class="msg-info">
                    <img src="../../assets/img/usertx.png" alt="">
                    <div class="msg">
                        <p class="msg-name"><span>{{item.messageName}}</span>({{item.bankName}})</p>
                        <p>{{item.messageTime}}</p>
                    </div>
                </div>
                <div class="msg-content">{{item.content}}</div>
                <div v-if="item.url" class="accessory">附件：<span @click="downloadFJ(item.fileName,item.url)">{{item.fileName}}</span></div>
            </div>
            <!-- <div class="msgs">
                <div class="msg-info">
                    <img src="../../assets/img/usertx.png" alt="">
                    <div class="msg">
                        <p class="msg-name"><span>刘宇锋</span>(南宁金电图腾软件有限公司)</p>
                        <p>2021-10-22 23:37:32</p>
                    </div>
                </div>
                <div class="msg-content">我来打卡啦</div>
            </div> -->
            <div>
                <textarea placeholder="请输入您的留言内容" v-model="textContent"></textarea>
                <div class="msg-btn">
                    <div class="leave" @click="submit()">留言</div>
                    <div class="back" @click="goBack">返回</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, ref, toRefs } from 'vue';
import { apiGetDialogueDetail,apiDownloadFJ,apiSaveMessage } from '../../api/axios';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
export default {
    setup() {
        let router = useRouter();
        let id = router.currentRoute.value.query.approvalId;
        const state = reactive({
            approvalData: {},
            messageList: []
        });
        const textContent = ref('');
        apiGetDialogueDetail({approvalId: id}).then(res => {
         console.log(res,'返回的数据')        
         if(res.code == '1'){
             state.approvalData = res.info.baseData;
             state.messageList = res.info.messageList;
          }
          else {
            Toast(res.msg);
          }
        })

        const downloadFJ = (name,url) => {
            apiDownloadFJ({url: url,fileName: name}).then(res => {
                console.log(res,'返回的数据')                   
                if(res.code == '1'){
                    console.log('下载附件')
                }
                else {
                    Toast(res.msg);
                }
            });
        }
        const submit = () => {
            if(!textContent.value) {
                Toast('请先输入留言内容')
            }
            apiSaveMessage({approvalId: id,content:textContent.value}).then(res => {
                console.log(res,'返回的数据')                   
                if(res.code == '1'){
                    Toast('留言成功！');
                    apiGetDialogueDetail({approvalId: id}).then(res => {      
                        if(res.code == '1'){
                            let len = res.info.messageList.length;
                            state.messageList.push(res.info.messageList(len));
                        }
                        else {
                            Toast(res.msg);
                        }
                    })
                }
                else {
                    Toast(res.msg);
                }
            });
        }
        return {
            ...toRefs(state),
            textContent,
            downloadFJ,
            submit
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1);
        },
    },
}
</script>
<style lang="less" scoped>
.chat{
    padding-top: 12.5vw;
    p.title {
        font-size: 5vw;
        font-weight: 600;
        margin: 4vw;
    }
    .card1 {
        position: relative;
        .nums {
            display: inline-block;
            color: #ff9900;
            border: 1px solid #ff9900;
            border-radius: 1vw;
            font-size: 4vw;
            padding: 0 2vw;
            
        }
        .company {
            color: #6699ff;
            font-size: 4.8vw;
            font-weight: 500;
            padding-top: 2vw;
        }
        .details {
            width: 100%;
            font-size: 4vw;
            margin-top: 2vw;
            .catalog {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #f5f5f5;
            p:first-child {
                width: 26vw;
                color: #666666;
                padding: 1.5vw 0;
            }
            p:last-child {
                width: 55vw;
                padding-left: 4vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            }
            .catalog:last-child {
                border-bottom: 0;
            }
            
        }
        img.status {
            width: 19.5vw;
            height: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

    }
    .card2 {
        .msgs {
            border-bottom: 1px solid #f5f5f5;
            margin-bottom: 4vw;
            .msg-info {
                display: flex;
                align-items: center;
                img {
                height: 15vw;
                width: 15vw;
                border-radius: 50%;
                margin-right: 4vw;
                }
                .msg {
                    width: 65vw;
                    font-size: 4vw;
                    color: #999;
                    .msg-name {
                        width: 100%;                        
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding-bottom: 2vw;
                        span {
                            color: #333;
                            margin-right: 1vw;                            
                        }
                    }
                }
            }
            .msg-content {
                font-size: 4vw; 
                padding: 4vw;               
            }
            .accessory {
                font-size: 4vw;
                span {
                    color: #6699ff;
                }
            }
        }
        .msgs:last-child {
            border-bottom: 0;
        }
        textarea {
            width: 100%;
            height: 30vw;
            resize: none;
            background-color: #f5f5f5;
            border-radius: 1.5vw;
            border: 0;
            padding: 2vw 4vw;
            font-size: 4vw;
            box-sizing: border-box;
        }
        .msg-btn {
            text-align: center;
            padding: 4vw 0;
            .leave {
                display: inline-block;
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                border-radius: 4vw;
                width: 20vw;
                padding: 1vw 0;
            }
            .back {
                display: inline-block;
                width: 20vw;
                border: 1px solid #ff9900;
                color: #ff9900;
                font-size: 4vw;
                padding: 1vw 0;
                border-radius: 4vw;
                margin-left: 4vw;
            }
        }
    }
}
</style>